import React from "react";
import styled from "@emotion/styled";
import RecentUpdate from "./RecentUpdate";
import Navigation from "./Navigation";
import Links from "./Links";
import { marginValue } from "./common";
import Page from "../../components/layouts/Page";

const Root = styled('div')(({theme}) => ({
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowX: 'hidden',
    paddingLeft: marginValue['xxl'],
    backgroundColor: theme.palette.background.default,
    '& .copyright': {
        position: 'absolute',
        bottom: 10,
        right: 10,
        fontSize: 14,
        color: theme.palette.text.disabled
    },
    '& .RecentUpdate': {
        width: '50vw'
    },
    '& .leftSide': {
        marginLeft: 30,
        width: '40vw',
        '& .intro': {
            fontSize: 26,
            color: theme.palette.text.primary,
            '& .name': {
                fontSize: 50,
                fontWeight: 900,
                padding: '10px 0',
                color: theme.palette.primary.main
            }
        },
        '&:before': {
            display: 'block',
            content: '""',
            position: 'absolute',
            top: 0,
            left: 70,
            width: 2,
            height: '100vh',
            backgroundColor: theme.palette.divider
        },
    },
    [theme.breakpoints.down('xl')]: {
        paddingLeft: marginValue['xl'],
    },
    [theme.breakpoints.down('lg')]: {
        padding: "0 70px",
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .leftSide': {
            marginLeft: 0,
            width: "100%",
            paddingTop: 70,
            "& .intro": {
                padding: "100px 0 50px 0",
                width: "100%"
            },
            '&:before': {
                display: 'none'
            },
        },
        '& .RecentUpdate': {
            width: '100%'
        },
        '& .copyright': {
            position: 'relative',
            top: 0,
            left: 0,
            fontSize: 14,
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
            textAlign: 'right',
            padding: 10,
            zIndex: 1,
            "&:after": {
                zIndex: -1,
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'translateX(-20%)',
                display: 'block',
                content: "''",
                width: "200%",
                height: "100%",
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    [theme.breakpoints.down('md')]: {
        padding: "0 50px",
        '& .leftSide': {
            '& .intro': {
                fontSize: 22,
                '& .name': {
                    fontSize: 45
                }
            }
        },
        '& .copyright': {
            // textAlign: 'right',
            textAlign: 'inherit',
            padding: 10,
            paddingRight: 30
        },
    },
    [theme.breakpoints.down('sm')]: {
        padding: 0,
        '& .leftSide, & .Links': {
            padding: "0 20px"
        },
        '& .leftSide': {
            paddingTop: 70
        }
    },
}));

const Home = props => {
    return (
        <Page title="Home">
            <Root className="Home">
                <div className="leftSide">
                    <div className="intro">
                        Hey, I'm
                        <div className="name" >Ian Chiu</div>
                        I am a student at National Central University from Taiwan. Currently focus on website and C++ development.
                    </div>
                    <Navigation />
                </div>
                <Links />
                <RecentUpdate />
                <div className="copyright">© 2022 IanChiu</div>
            </Root>
        </Page>
    );
}

export default Home;
