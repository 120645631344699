import React from 'react';
import styled from '@emotion/styled';

const Root = styled('div')(({ theme }) => ({
    margin: "0 auto",
    padding: "0 15px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.up('md')]: {
        maxWidth: "800px"
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: "900px"
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: "1000px"
    },
    [theme.breakpoints.up('xxl')]: {
        maxWidth: "1100px"
    }
}));

const Container = (props) => {
    return (
        <Root className={`Container ${props.className}`}>
            {props.children}
        </Root>
    );
}

export default Container;

