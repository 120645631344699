import { React } from 'react';
import styled from '@emotion/styled';
import Page from '../../components/layouts/Page';
import Footer from '../../components/layouts/Footer';
import WarningSvg from '../../assets/images/warning.svg';

const Root = styled('div')(({theme}) => ({
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.main,
    "& .wrapper": {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        "& img": {
            width: "30vw"
        },
        "& .text": {
            marginTop: 20,
            color: theme.palette.text.primary
        }
    },
    "& footer": {
        position: 'absolute',
        bottom: 0
    },
    [theme.breakpoints.down('lg')]: {
        "& .wrapper": {
            "& img": {
                width: "50vw"
            },
            "& .text": {
                fontSize: 20
            }
        },
    },
    [theme.breakpoints.down('sm')]: {
        "& .wrapper": {
            "& img": {
                width: "80vw"
            }
        },
    }
}));

const NotFound = () => {

    return (
        <Page title="NotFound">
            <Root>
                <div className="wrapper">
                    <img src={WarningSvg} alt="404 not found" />
                    <h3 className="text">Page not found</h3>
                </div>
                <Footer />
            </Root>
        </Page>
    )
};

export default NotFound;
