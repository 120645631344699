import React from "react";
import { Tooltip } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const LinkItem = props => {
    const theme = useTheme();
    const downLgSize = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Tooltip
            className="linkItem"
            disableHoverListener={!props.tooltip}
            placement={downLgSize ? "bottom" : "right"}
            title={props.title}
        >
            <a href={props.link} target="_blank" rel="noreferrer">
                {props.icon}
            </a>
        </Tooltip>
    );
}

export default LinkItem;
