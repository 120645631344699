import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { themeActions } from '../../../../store/theme';
import styled from "@emotion/styled";
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 80,
    "& .toggleThemeBtn": {
        border: `solid 1px ${theme.palette.primary.main}`,
        "& svg": {
            color: theme.palette.primary.main,
        }
    }
}));

const Settings = () => {
    const theme = useSelector(state => state.theme.currentTheme);
    const dispatch = useDispatch();

    const toggleThemeHandler = () => {
        dispatch(themeActions.toggle());
    };

    return (
        <Root>
            <IconButton
                className="toggleThemeBtn"
                edge="end"
                color="inherit"
                aria-label="mode"
                onClick={toggleThemeHandler}
            >
                {theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
        </Root>
    );
}

export default Settings;
