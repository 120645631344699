import axios from './instance/axios';

const BlogService = {
    getBlog: async (blogId) => {
        try {
            const response = await axios.get(`/blogs/${blogId}?populate=cover_image`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getBlogList: async () => {
        try {
            const response = await axios.get("/blogs?populate=cover_image");
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default BlogService;
