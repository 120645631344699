import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';

const Root = styled('div')(({theme, imgUrl}) => ({
    margin: 15,
    width: "calc(100% / 2 - 30px)",
    minHeight: 400,
    "& .imgWrapper": {
        width: '100%',
        position: 'relative',
        maxWidth: 'none',
        paddingTop: '56.25%',
        marginRight: 0,
        borderRadius: 10,
        overflow: 'hidden',
        transition: '0.5s',
        "&.loading": {
            transform: 'none'
        },
        "&:hover": {
            transform: 'scale(1.02)',
            "&.loading": {
                transform: 'none'
            }
        },
        "& .bgImage": {
            paddingTop: '56.25%',
            backgroundImage: `url(${imgUrl})`,
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: "absolute",
            left: 0,
            zIndex: 0,
            top: 0,
            objectFit: 'cover',
        },
    },
    "& .abstract": {
        color: theme.palette.text.primary
    },
    "& .title": {
        paddingTop: 10,
        "& .date": {
            color: theme.palette.text.disabled,
            fontWeight: 900
        },
        "& .text": {
            fontWeight: 900,
            color: theme.palette.primary.main,
            // filter: 'brightness(85%)'
        }
    },
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        minHeight: 0,
        "&:last-of-type": {
            marginBottom: 0
        }
    },
}));

const BlogCard = props => {

    if (props.loading) {
        return (
            <Root
                key={props.id}
                id={props.id}
                className="blogCard loading"
            >
                <div>
                    <Skeleton className="imgWrapper loading" animation="wave" height={0} />
                    <div className="title">
                        <Skeleton className="date loading" animation="wave" width={100} height={20} />
                        <Skeleton className="text loading" animation="wave" width={300} />
                    </div>
                </div>
                <div className="abstract loading">
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={20} />
                </div>
            </Root>
        )
    }
    else {
        return (
            <Root
                key={props.id}
                id={props.id}
                className="blogCard"
                imgUrl={props.imgUrl}
            >
                <NavLink to={`/article/${props.id}`}>
                    <div className="imgWrapper">
                        <div className="bgImage"></div>
                    </div>
                    <div className="title">
                        <h5 className="date">{props.date}</h5>
                        <h3 className="text">{props.title}</h3>
                    </div>
                </NavLink>
                <p className="abstract">{props.abstract}</p>
            </Root>
        )
    }

};

export default BlogCard;
