import { React } from 'react';
import styled from '@emotion/styled';
import LinkItems from '../../LinkItems';
import NavigationItems from '../../NavigationItems';

const Root = styled('footer')(({theme}) => ({
    position: 'relative',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 10px',
    "& .left-wrapper": {
        display: 'flex',
        alignItems: 'center',
        "& .copyright": {
            color: theme.palette.text.disabled,
        },
        "& .linkItems": {
            marginLeft: 10,
            marginRight: 10,
            transform: 'translateY(3px)',
            "& svg": {
                color: theme.palette.text.primary,
                marginLeft: 10,
                opacity: 0.2,
                "&:hover": {
                    transition: '0.5s',
                    opacity: 1
                }
            }
        },
    },
    "& .navItems": {
        justifySelf: 'flex-end',
        "& a": {
            "&::after": {
                display: 'none'
            },
            opacity: 0.2,
            "&:hover": {
                transition: '0.5s',
                opacity: 1
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        "& .navItems": {
            display: 'none'
        }
    },
}));

const Footer = props => {
    return (
        <Root>
            <div className='left-wrapper'>
                <div className="copyright">© 2022 Ian Chiu</div>
                <LinkItems />
            </div>
            <NavigationItems />
        </Root>
    )
};

export default Footer;
