import React from "react";
import styled from "@emotion/styled";

const Root = styled('div')(({theme, index, size}) => ({
    borderRadius: 5,
    width: "100%",
    height: 5,
    backgroundColor: `${theme.palette.primary.main}55`,
    position: 'relative',
    "& .index": {
        borderRadius: 5,
        transition: '0.3s',
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        width: `${1 / size * 100}%`,
        position: 'absolute',
        top: 0,
        left: `${index / size * 100}%`,
    }
}));

const IndexBar = props => {
    return (
        <Root className="IndexBar" index={props.index} size={props.size} >
            { props.loading ? null : <div className="index" />}
        </Root>
    );
}

export default IndexBar;
