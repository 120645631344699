import axios from './instance/axios';

const WorkService = {
    getWorkList: async () => {
        try {
            const response = await axios.get("/works?populate=image");
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default WorkService;
