import axios from './instance/axios';

const RecentService = {
    getRecentList: async () => {
        try {
            const response = await axios.get("/recents?populate=*&sort[0]=createdAt%3Adesc");
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default RecentService;
