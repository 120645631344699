import { createTheme } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#F5F5F5",
            paper: '#FFFFFF',
            glare: "#BBBBBB"
        },
        primary: {
            original: indigo[700],
            main: indigo[700],
            light: indigo[50],
            dark: indigo[900],
            articleIntroBg: indigo[700],
            articleBg: "#F5F5F5",
            sideMenuBg: '#FFFFFF'
        },
        secondary: {
            main: indigo[50],
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400
        },
        containerMaxWidth: {
            xs: 0,
            sm: 0,
            md: 720,
            lg: 960,
            xl: 1140,
            xxl: 1320
        }
    }
});

export default theme;
