import React from "react";
import styled from "@emotion/styled";
import NavigationItems from "../../../components/NavigationItems";

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    '& .navItems': {
        width: 300,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        bottom: 50,
        fontSize: 26,
        fontWeight: 900,
        "& a": {
            marginRight: 50,
            color: theme.palette.text.primary,
        },
        "& .homeNav": {
            display: 'none'
        },
    },
    [theme.breakpoints.down('lg')]: {
        display: 'none'
    },
}));

const Navigation = props => {

    return (
        <Root className="Navigation">
            <NavigationItems />
        </Root>
    );
}

export default Navigation;
