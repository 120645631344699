import React from 'react';
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)(({theme}) => ({
    color: theme.palette.text.primary,
    padding: '0 0.5vw',
    textAlign: 'center',
    fontWeight: '600',
    transition: "0.2s",
    '&:after': {
        content: '""',
        display: 'block',
        position: 'relative',
        top: 1,
        width: '0%',
        height: 3,
        backgroundColor: theme.palette.primary.main,
        transition: '0.2s'
    },
    '&:hover, &.active' :{
        color: theme.palette.text.primary,
        '&:after': {
            width: '100%',
        }
    }
}));

const NavigationItem = (props) => {
    return (
        <StyledNavLink className={props.className} to={props.link}>
            <span>{props.children}</span>
        </StyledNavLink>
    );
};

export default NavigationItem;
