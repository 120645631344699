import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Container from '../../components/layouts/Container';
import BlogService from '../../services/BlogService';
import TitleBar from '../../components/layouts/TitleBar';
import BlogCard from './Card';
import Page from '../../components/layouts/Page';
import Footer from '../../components/layouts/Footer';

const Root = styled('div')(({theme}) => ({
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "& .cardDeck": {
        width: "100%",
        marginTop: 30,
        display: 'flex',
        flexWrap: 'wrap',
        "&:after": {
            content: "''",
            flex: 'auto',
            margin: 15,
        },
        "&.loading": {
            "&:after": {
                display: 'none'
            }
        }
    },
    [theme.breakpoints.down('sm')]: {
        "& .cardDeck": {
            marginTop: 15,
            paddingBottom: 15
        }
    }
}));

const Blog = () => {
    const [cards, setCards] = useState([
        <BlogCard key={1} loading={true} />,
        <BlogCard key={2} loading={true} />
    ]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updatedCards = [];
        BlogService.getBlogList().then(res => {
            for (const data of res.data) {
                const attributes = data.attributes;
                const imageFormat = attributes.cover_image.data.attributes.formats;
                let imgUrl = process.env.REACT_APP_BACKEND_PUBLIC;
                if (imageFormat.small) {
                    imgUrl += imageFormat.small.url;
                }
                else if (imageFormat.thumbnail) {
                    imgUrl += imageFormat.thumbnail.url;
                }
                updatedCards.push(
                    <BlogCard
                        id={data.id}
                        key={data.id}
                        title={attributes.title}
                        abstract={attributes.abstract}
                        date={attributes.upload_date}
                        imgUrl={imgUrl}
                    />
                );
            }
            setCards(updatedCards);
            setLoading(false);
        }).catch(err => console.error(err));
    }, []);

    return (
        <Page title="Blog">
            <Root>
                <div>
                    <TitleBar
                        mainTitle="BLOG"
                        subTitle="A place to voice my useless opinions."
                    />
                    <Container className={`cardDeck ${loading ? 'loading' : 'completed'}`}>
                        {cards}
                    </Container>
                </div>
                <Footer />
            </Root>
        </Page>
    )
};

export default Blog;
