import { React } from 'react'
import styled from '@emotion/styled';
import NavigationItems from '../../../NavigationItems';
import { Drawer } from '@mui/material';

const Root = styled(Drawer)(({theme}) => ({
    position: 'relative',
    zIndex: 2,
    "& .paper": {
        width: 350,
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.articleBg,
        "& .navItems": {
            display: 'flex',
            flexDirection: 'column',
            "& a": {
                fontSize: 45,
                marginTop: 30,
                marginRight: 'auto'
            }
        }
    },
    [theme.breakpoints.down('sm')]: {
        "& .paper": {
            width: 200,
            "& .navItems": {
                "& a": {
                    fontSize: 30,
                    marginTop: 40
                }
            }
        }
    }
}));

const SideMenu = (props) => {

    return (
        <Root
            className={`SideMenu ${props.open ? "open" : ""}`}
            open={props.open}
            anchor="left"
            onClose={props.onClose}
        >
            <div className="paper">
                <NavigationItems />
            </div>
        </Root>
    )
}

export default SideMenu;
