import { React, useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import { marginValue } from "../common";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import IndexBar from "./IndexBar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import RecentService from "../../../services/RecentService";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Root = styled('div')(({ theme }) => ({
    width: 1000,
    marginLeft: 50,
    zIndex: 0,
    '&:before': {
        display: 'block',
        content: '""',
        zIndex: -1,
        position: 'absolute',
        top: 0,
        right: 0,
        width: '35vw',
        height: '100vh',
        backgroundColor: theme.palette.secondary.main
    },
    "& .text": {
        fontSize: 20,
        color: theme.palette.text.primary
    },
    "& .swiperLoading, & .swiper": {
        marginTop: 20,
        zIndex: 1,
        height: 300,
        padding: '20px 10px'
    },
    "& .swiperLoading": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.5
    },
    "& .swiper": {
        width: 900,
        "& .swiper-slide": {
            // border: 'solid 1px black',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '2px 2px 10px #00000011',
            borderRadius: 10,
            "& .paper": {
                width: '100%',
                height: '100%',
                "& .content": {
                    padding: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    "& .title, & .abstract": {
                        // textAlign: 'justify',
                        // line clamping
                        display: "-webkit-box",
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                    },
                    "& .label": {
                        color: theme.palette.text.secondary,
                        fontWeight: 900
                    },
                    "& .title": {
                        WebkitLineClamp: 2,
                        // height: '2.3em'
                        margin: '20px 0',
                        color: theme.palette.primary.main
                    },
                    "& .abstract": {
                        WebkitLineClamp: 3,
                        color: theme.palette.text.primary
                        // lineHeight: 1
                    }
                },
                position: 'relative',
                "& .learnMoreBtn": {
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                    borderRadius: 0,
                    backgroundColor: theme.palette.divider,
                    color: theme.palette.text.secondary
                }
            }
        }
    },
    "& .control": {
        display: 'flex',
        transform: 'translateY(40px)',
        marginRight: marginValue['xxl'],
        justifyContent: 'space-between',
        alignItems: 'center',
        "& .progressBar": {
            flex: 9,
            marginLeft: 5,
            height: 5,
            borderRadius: 50
        },
        "& .buttons": {
            minWidth: 50,
            position: 'relative',
            top: 3,
            flex: 1,
            marginLeft: 20,
            "& svg:hover": {
                cursor: 'pointer'
            }
        }
    },
    [theme.breakpoints.down('xl')]: {
        "& .control": {
            marginRight: marginValue['xl'],
        }
    },
    [theme.breakpoints.down('lg')]: {
        '&:before': {
            display: 'none'
        },
        backgroundColor: theme.palette.secondary.main,
        marginLeft: 0,
        position: 'relative',
        padding: "50px 0",
        "&:after": {
            zIndex: -1,
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translateX(-20%)',
            display: 'block',
            content: "''",
            width: "200%",
            height: "100%",
            backgroundColor: theme.palette.secondary.main,
        },
        "& .swiper": {
            width: "100%"
        },
        "& .control": {
            transform: 'translate(15px, 20px)',
            marginRight: 0,
        }
    },
    [theme.breakpoints.down('md')]: {
        "& .swiper": {
            width: 800,
        },
    },
    [theme.breakpoints.down('sm')]: {
        "& .text": {
            paddingLeft: 20
        },
        "& .swiper": {
            height: 280,
            width: "155vw",
            "& .swiper-slide": {
                "& .paper": {
                    width: '100%',
                    height: '100%',
                    "& .content": {
                        padding: 20,
                        "& .label": {

                        },
                        "& .title": {
                            margin: '20px 0',
                        },
                        "& .abstract": {

                        }
                    },
                }
            }
        },
        "& .control": {
            display: 'none'
        }
    },
}));

const RecentUpdate = () => {
    const ref = useRef(null);
    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [slides, setSlides] = useState(null);
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState(0);

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    useEffect(() => {
        RecentService.getRecentList().then(res => {
            const updatedSlides = [];
            for (let item of res.data) {
                let data = item.attributes.work.data;
                let isWork = true;
                if (!data) {
                    data = item.attributes.blog.data;
                    isWork = false;
                }
                let learnMoreBtn =
                        <Button
                            href={`/article/${data.id}`}
                            className="learnMoreBtn"
                            variant="contained"
                            disableElevation
                        >LEARN MORE...</Button>

                if (isWork) {
                    learnMoreBtn =
                        <Button
                            href={data.attributes.link}
                            target="_blank"
                            className="learnMoreBtn"
                            variant="contained"
                            disableElevation
                        >LEARN MORE...</Button>
                }
                if (data) {
                    updatedSlides.push(
                        <SwiperSlide className='swiper-slide' key={item.id}>
                            <div className='paper'>
                                <div className="content">
                                    <div className="label">{isWork ? "WORK" : "BLOG"}</div>
                                    <h3 className='title'>{data.attributes.title}</h3>
                                    <p className='abstract'>{data.attributes.abstract}</p>
                                </div>
                                {learnMoreBtn}
                            </div>
                        </SwiperSlide>
                    );
                }
            }
            setSlides(updatedSlides);
            setLoading(false);
        }).catch(err => console.error(err));
    }, []);

    let swiperJsx =
        <div className="swiperLoading">
            <CircularProgress />
        </div>;

    if (!loading && slides.length) {
        swiperJsx =
            <Swiper
                ref={ref}
                className="swiper"
                spaceBetween={downSm ? 20 : 30}
                slidesPerView={2}
                loop
                onSlideChange={(event) => setIndex(event.realIndex)}
            >
                {slides}
            </Swiper>;
    }
    else if (!loading) {
        swiperJsx =
            <div className="swiperLoading">
                <h3>No Data</h3>
            </div>;
    }

    return (
        <Root className="RecentUpdate">
            <div className="text">Recent Updates</div>
            {swiperJsx}
            <div className="control">
                <IndexBar index={index} size={slides ? slides.length : 1} loading={loading} />
                <div className="buttons">
                    <NavigateBeforeIcon onClick={goPrev} color="primary" />
                    <NavigateNextIcon onClick={goNext} color="primary" />
                </div>
            </div>
        </Root>
    );
}

export default RecentUpdate;
