import "./assets/sass/_normalize.scss";
import "./assets/sass/_typography.scss";

import React from "react";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import styled from '@emotion/styled';
import { AnimatePresence } from "framer-motion";

import getTheme from "./themes/base";
import Header from "./components/layouts/Header";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Article from "./pages/Article";
import Work from "./pages/Work";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
}));

const App = () => {
    const theme = useSelector((state) => state.theme.currentTheme);
    const appliedTheme = getTheme(theme);

    return (
        <ThemeProvider theme={appliedTheme}>
            <Root className="App">
                <Header />
                <AnimatePresence exitBeforeEnter>
                    <Routes>
                        <Route path='*' element={<NotFound />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/work" element={<Work />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/article/:id" element={<Article />} />
                    </Routes>
                </AnimatePresence>
            </Root>
        </ThemeProvider>
    );
}

export default App;
