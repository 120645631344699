import { React, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import WorkService from '../../services/WorkService';
import Container from '../../components/layouts/Container';
import TitleBar from '../../components/layouts/TitleBar';
import WorkCard from './Card';
import Page from '../../components/layouts/Page';
import Footer from '../../components/layouts/Footer';

const Root = styled('div')(({theme}) => ({
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "& .cardDeck": {
        marginTop: 30,
        paddingBottom: 20,
        display: 'flex',
        flexWrap: 'wrap',
        "& .workCard": {
            // flex: 1,
            margin: 15
        }
    },
    [theme.breakpoints.down('xl')]: {
        "& .cardDeck.loading": {
            "& .workCard:nth-of-type(3)": {
                display: 'none'
            }
        }
    }
}));

const Work = () => {
    let loadingCards = [
        <WorkCard key={1} loading/>,
        <WorkCard key={2} loading/>,
        <WorkCard key={3} loading/>
    ];

    const [cards, setCards] = useState(loadingCards);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updatedCards = [];
        WorkService.getWorkList().then(res => {
            for (const data of res.data) {
                const attributes = data.attributes;
                const imageFormat = attributes.image.data.attributes.formats;
                let imgUrl = process.env.REACT_APP_BACKEND_PUBLIC;
                if (imageFormat.small) {
                    imgUrl += imageFormat.small.url;
                }
                else if (imageFormat.thumbnail) {
                    imgUrl += imageFormat.thumbnail.url;
                }
                updatedCards.push(
                    <WorkCard
                        id={data.id}
                        key={data.id}
                        title={attributes.title}
                        abstract={attributes.abstract}
                        imgUrl={imgUrl}
                        link={attributes.link}
                    />
                );
            }
            setCards(updatedCards);
            setLoading(false);
        }).catch(err => console.error(err));
    }, []);

    return (
        <Page title="Work">
            <Root>
                <div>
                    <TitleBar
                        mainTitle="WORK"
                        subTitle="Here are some things I have made and done."
                    />
                    <Container className={`cardDeck ${loading ? 'loading' : 'ready'}`}>
                        {cards}
                    </Container>
                </div>
                <Footer />
            </Root>
        </Page>
    )
};

export default Work;
