import React from 'react';
import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Root = styled(Card)(({theme, imgUrl}) => ({
    width: 'calc(100% / 3 - 30px)',
    "&.ready": {
        "&:hover, &:active": {
            transition: '0.3s',
            filter: 'brightness(90%)',
            cursor: 'pointer'
        },
        "&:active": {
            filter: 'brightness(70%)'
        },
    },
    "& img": {
        minHeight: 155
    },
    "& .content": {
        // minHeight: '200px'
        "& .title, & .abstract": {
            display: "-webkit-box",
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
        },
        "& .title": {
            WebkitLineClamp: 2,
        },
        "& .abstract": {
            WebkitLineClamp: 4,
            fontSize: 14,
            color: theme.palette.text.secondary,
            margin: 0
        }
    },
    [theme.breakpoints.down('xl')]: {
        width: 'calc(100% / 2 - 30px)',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    }
}));

const WorkCard = props => {
    return (
        <Root
            key={props.id}
            id={props.id}
            className={`workCard ${props.loading ? "loading" : "ready"}`}
            onClick={() => window.open(`${props.link}`, '_blank')}
        >
            {props.loading ? (
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            ) : (
                <CardMedia
                    component="img"
                    alt="cover"
                    image={props.imgUrl}
                />
            )}
            <CardContent className="content">
                {props.loading ? (
                    < >
                        <Skeleton className="text loading" animation="wave" width={150} height={40} />
                        <Skeleton animation="wave" height={20} />
                        <Skeleton animation="wave" height={20} />
                        <Skeleton animation="wave" height={20} />
                    </>
                ) : (
                    < >
                        <h3 className="title">{props.title}</h3>
                        <p className="abstract">{props.abstract}</p>
                    </>
                )}

            </CardContent>
        </Root>
    );

};

export default WorkCard;
