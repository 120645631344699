import React from 'react'
import styled from '@emotion/styled';

const Root = styled('div')(({theme}) => {
    const width = 35;
    const height = 22;
    const lineHeight = 4;
    return ({
        position: 'fixed',
        top: 25,
        left: 15,
        zIndex: 3,
        height: height,
        width: width,
        display: "block",
        cursor: "pointer",
        "&.notShow": {
            display: 'none',
        },
        "& .bar": {
            width: width,
            height: lineHeight,
            display: "block",
            borderRadius: "2px",
            backgroundColor: theme.palette.primary.main,
            transitionProperty: "transform, opacity, top, left",
            transitionDuration: ".2s",
            transitionTimingFunction: "inherit",
            position: "absolute",
            "&:nth-of-type(1)":{
                top: 0
            },
            "&:nth-of-type(2)": {
                top: (height - lineHeight)/2
            },
            "&:nth-of-type(3)": {
                top: height - lineHeight
            }
        },
        "&.active": {
            "& span:nth-of-type(1)": {
                transform: "rotate3d(0, 0, 1, 45deg)",
                top: height/2 - lineHeight/2
            },
            "& span:nth-of-type(2)": {
                opacity: 0
            },
            "& span:nth-of-type(3)": {
                transform: "rotate3d(0, 0, 1, -45deg)",
                top: height/2 - lineHeight/2
            }
        }
    })
});

const MenuToggler = (props) => {
    return (
        <Root
            className={`MenuToggler ${props.active ? "active" : ""} ${props.className}`}
            onClick={props.click}
        >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
        </Root>
    )
}

export default MenuToggler;

