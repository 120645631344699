import { React, useEffect, useState } from 'react';
import { useNavigate ,useParams } from 'react-router';
import styled from '@emotion/styled';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import BlogService from '../../services/BlogService';
import Markdown from 'markdown-to-jsx';
import Page from '../../components/layouts/Page';
import Loader from '../../components/Loader';
import { useSelector } from "react-redux";
import { useTheme } from '@emotion/react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

const LoadingContainer = styled('div') (({theme}) => ({
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.articleIntroBg
}));

const Root = styled('div')(({theme, imgUrl}) => ({
    "& .intro, & .content": {
        paddingLeft: 15,
        paddingRight: 15,
        "& .container": {
            maxWidth: 900,
        },
    },
    "& .intro": {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
        backgroundColor: theme.palette.primary.articleIntroBg,

        "& > *": {
            color: 'white'
        },
        "& .goBackBtn": {
            // position: 'absolute',
            // top: 70,
            border: `solid 1px #fff`,
            marginRight: 'auto',
            marginBottom: 30,
            "& svg": {
                fontSize: 50,
                transform: 'translateX(-5%)',
                color: 'white'
            }
        },
        "& .title": {
            fontSize: 70,
            padding: '30px 0'
        },
        "& .abstract": {
            fontSize: 30,
            lineHeight: '1.5em'
        },
        "& .date": {
            fontSize: 20,
            marginTop: 50
        }
    },
    "& .bgImage": {
        backgroundImage: `url(${imgUrl})`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 0,
        objectFit: 'cover'
    },
    "& .content": {
        paddingTop: 50,
        position: 'relative',
        marginTop: '80vh',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.articleBg,
        "& img": {
            maxWidth: '100%',
            // maxHeight: 400
        },
        "& h1, & h2, & h3, & h4": {
            fontWeight: 900
        },
        "& a": {
            color: theme.palette.info.main
        },
        "& ol, & ul": {
            paddingLeft: 20
        },
        "& p, & li": {
            // fontSize: 17.6
            lineHeight: 1.6
        },
        "& code": {
            "& span": {
                fontFamily: 'Consolas'
            }
        }
    },
    "& .backToTopBtn": {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: 20,
        marginBottom: 30
    },
    [theme.breakpoints.down('lg')]: {
        "& .intro": {
            "& .title": {
                fontSize: 60,
            },
            "& .abstract": {
                fontSize: 25,
            },
            "& .date": {
                fontSize: 18,
            },
        },
    },
    [theme.breakpoints.down('md')]: {
        "& .intro": {
            "& .title": {
                fontSize: 50,
            },
            "& .abstract": {
                fontSize: 22,
            },
            "& .date": {
                fontSize: 16,
            },
        },
    },
    [theme.breakpoints.down('sm')]: {
        "& .intro": {
            "& .goBackBtn": {
                border: `solid 1px #fff`,
                marginRight: 'auto',
                marginBottom: 30,
                "& svg": {
                    fontSize: 40,
                    transform: 'translateX(-5%)',
                    color: 'white'
                }
            },
        },
        "& .content": {
            display: 'block'
        },
    }
}));

const Article = props => {
    const navigate = useNavigate();
    const theme = useTheme();
    const params = useParams();
    const isDark = useSelector((state) => state.theme.currentTheme) === 'dark';
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleBackTopTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }

    useEffect(() => {
        BlogService.getBlog(params.id).then(res => {
            const attributes = res.data.attributes;
            setData({
                imgUrl: process.env.REACT_APP_BACKEND_PUBLIC + attributes.cover_image.data.attributes.url,
                abstract: attributes.abstract,
                content: attributes.content,
                title: attributes.title,
                date: attributes.upload_date
            });
            setLoading(false);
        }).catch(err => console.error(err));
    }, [params.id]);

    if (loading) {
        return (
            <Page title="Loading...">
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            </Page>
        )
    } else {
        return (
            <Page title={data.title}>
                <Root className="root" imgUrl={data.imgUrl}>
                    <div className="intro">
                        <div className="container">
                            <IconButton
                                className="goBackBtn"
                                onClick={() => navigate('/blog')}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <h1 className="title">{data.title}</h1>
                            <h3 className="abstract">{data.abstract}</h3>
                            <div className="date">{data.date} / IanChiu</div>
                        </div>
                    </div>
                    <div className="bgImage" />
                    <div className="contentBg"/>
                    <div className="content">
                        <div className='container'>
                            <Markdown
                                className="markdown"
                                style={{
                                    color: theme.palette.text.primary
                                }}
                                options={{
                                    overrides: {
                                        SyntaxHighlighter: {
                                            component: SyntaxHighlighter,
                                            props: {
                                                style: isDark ? darcula : prism
                                            }
                                        }
                                    }
                                }}
                            >{data.content}</Markdown>
                            <Button
                                className="backToTopBtn"
                                startIcon={<ArrowUpwardIcon />}
                                onClick={handleBackTopTop}
                                size="large"
                            >BACK TO TOP</Button>
                        </div>
                    </div>
                </Root>
            </Page>
        );
    }
};

export default Article;
