
const marginValue = {
    xs: 0,
    sm: 0,
    md: 30,
    lg: 50,
    xl: 70,
    xxl: 80
}

export { marginValue }
