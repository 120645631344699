import { React } from "react";
import styled from '@emotion/styled';

const Root = styled('div')(({ theme }) => ({
    "@keyframes dotFlashing": {
        "0%": {
            backgroundColor: "#ffffff22",
        },
        "50%, 100%": {
            backgroundColor: "#ffffffaa",
        }
    },

    position: "relative",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#ffffff22",
    color: "#ffffff22",
    animation: "dotFlashing 1s infinite linear alternate",
    animationDelay: "0.5s",

    "&:before, &:after": {
        content: "''",
        display: "inline-block",
        position: "absolute",
        top: 0,
    },

    "&:before": {
        left: "-30px",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "#ffffff22",
        color: "#ffffff22",
        animation: "dotFlashing 1s infinite alternate",
        animationDelay: "0s",
    },

    "&:after": {
        left: "30px",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "#ffffff22",
        color: "#ffffff22",
        animation: "dotFlashing 1s infinite alternate",
        animationDelay: "1s",
    }
}));

const Loader = () => {

    return (
        <Root className="dot-flashing">

        </Root>
    )
};

export default Loader;
