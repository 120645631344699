import React from "react";
import styled from "@emotion/styled";
import LinkItems from "../../../components/LinkItems";

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    position: 'absolute',
    left: -120,
    '& .linkItems': {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 5,
        // paddingTop: 20,
        '& .linkItem': {
            color: theme.palette.primary.main,
            marginBottom: 40,
            '&:hover': {
                filter: 'brightness(200%)',
                transition: '0.5s'
            },
            "& svg": {
                fontSize: 28,
            }
        }
    },
    [theme.breakpoints.down('lg')]: {
        position: 'relative',
        left: 0,
        paddingBottom: 50,
        '& .linkItems': {
            // width: "100%",
            flexDirection: 'row',
            '& .linkItem': {
                marginBottom: 0,
                '& svg': {
                    fontSize: 40,
                },
            },

            "& .leetcode-svg": {
                paddingLeft: 20,
                paddingRight: 20
            }
        },
    },
    [theme.breakpoints.down('md')]: {
        '& .linkItems': {
            '& svg': {
                fontSize: 35
            }
        },
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: 30
    },
}));

const Links = props => {

    return (
        <Root className="Links">
            <LinkItems tooltip />
        </Root>
    );
}

export default Links;
