import React from 'react';
import styled from '@emotion/styled';
import Container from '../Container';

const Root = styled('div')(({theme}) => ({
    borderBottom:  `solid 1px ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    "& .Container": {
        paddingTop: 70,
        width: '100%',
        "& .title": {
            padding: '25px 0',
            display: 'flex',
            alignItems: 'center',
            '& .mainTitle': {
                padding: '5px 10px',
                fontSize: 20,
                fontWeight: 900,
                color: theme.palette.background.paper,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 5
            },
            '& .subTitle': {
                marginLeft: 20,
                fontWeight: 900,
                fontSize: 18,
                color: theme.palette.text.secondary
            }
        }
    },
    [theme.breakpoints.down('sm')]: {
        "& .Container": {
            "& .title": {
                '& .mainTitle': {
                    padding: '3px 6px',
                    fontSize: 16,
                },
                '& .subTitle': {
                    marginLeft: 20,
                    fontWeight: 900,
                    fontSize: 14
                }
            }
        }
    }
}));

const TitleBar = (props) => {
    return (
        <Root className={props.className}>
            <Container>
                <div className="title">
                    <div className="mainTitle">
                        {props.mainTitle}
                    </div>
                    <div className="subTitle">
                        {props.subTitle}
                    </div>
                </div>
                {props.children}
            </Container>
        </Root>
    );
}

export default TitleBar;
