import React from 'react';
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import styled from '@emotion/styled';

const Root = styled(motion.div) (({theme}) => ({
    height: '100vh'
}));

const Page = (props) => {
    const title = props.title ? props.title + " | Ian Chiu" : "Ian Chiu";

    return (
        <Root
            initial="initial"
            animate="animate"
            exit="exit"
            className={props.className}
            variants={variants.fadeInUp}
        >
            <Helmet>
                <meta property="og:title" content={title} />
                <meta property="og:description" content="Hello, I am Ian Chiu. I am a student of National Central University." />
                <title>{title}</title>
            </Helmet>
            {props.children}
        </Root>
    );
};

export default Page;

export const variants = {
    fadeInUp: {
        initial: {
            opacity: 0,
            y: 20
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: 'easeOut',
            }
        },
        exit: {
            y: -20,
            opacity: 0,
            transition: {
                ease: 'easeIn',
            }
        }
    }
};
