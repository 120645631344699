import React from 'react';
import NavigationItem from './NavigationItem';

const NavigationItems = () => {
    return (
        <nav className="navItems">
            <NavigationItem className="homeNav" link='/' >Home</NavigationItem>
            <NavigationItem link='/blog' >Blog</NavigationItem>
            <NavigationItem link='/work' >Work</NavigationItem>
            <NavigationItem link='/about' >About</NavigationItem>
        </nav>
    );
}

export default NavigationItems;
