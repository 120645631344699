import { createTheme } from '@mui/material';
import { indigo } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#1e2021",
            paper: '#1b1d1d',
            glare: "#303040"
        },
        primary: {
            original: indigo[300],
            main: indigo[300],
            light: indigo[50],
            dark: indigo[900],
            articleIntroBg: indigo[700],
            articleBg: '#1e2021',
            sideMenuBg: "#212425"
        },
        secondary: {
            main: '#212425'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400
        }
    }
});

export default theme;
