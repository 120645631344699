import { React, useEffect, useState } from "react";
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import MenuToggler from "./MenuToggler";
import Settings from "./Settings";
import SideMenu from "./SideMenu";

const Root = styled('header')(({theme}) => ({
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: "fixed",
    top: "0",
    width: "100vw",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 2px #00000022',
    padding: 15,
    "& .hidden": {
        width: 35
    },
    "&.notShow": {
        display: 'none',
    },
    "&.atHome": {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    "& .logo": {
        fontWeight: 900,
        fontSize: 20,
        color: theme.palette.primary.main,
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const Header = () => {
    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('lg'));
    let navigate = useNavigate();
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const atHome = !matched && window.location.pathname === '/';
    const notShow = window.location.pathname.includes('/article');

    const sideMenuToggleHandler = () => {
        setSideMenuOpen(!sideMenuOpen);
    }

    useEffect(() => {
        setSideMenuOpen(false);
    }, [window.location.pathname])

    return (
        <>
            <MenuToggler className={`${notShow ? 'notShow' : 'show'}`} active={sideMenuOpen} click={sideMenuToggleHandler} />
            <Root className={`${atHome ? 'atHome' : 'general'} ${notShow ? 'notShow' : 'show'}`}>
                <div className="hidden" />
                <div className="logo" onClick={() => navigate("/")} >Ian Chiu</div>
                <Settings />
                <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
            </Root>
        </>

    )
};

export default Header;
